import {version} from '../hooks/data'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'
import { useState, useEffect } from "react"
import {APICALL_URL} from '../hooks/data'

function Compte({action, isOnline}){
    const userLocal = JSON.parse(localStorage.getItem("user"))
    const[success, setSuccess] = useState(false)
    const role=userLocal.roles
    const[loadingData, setLoadingData] = useState(false)

    const navigate = useNavigate()
    
    useEffect(() => {
        setLoadingData(true)
    }, []);

    useEffect(() => {
        if(isOnline){
            if(loadingData){
                setSuccess(false)
                const postData = {
                  action:"getUserInfos",
                  accessToken: userLocal.accessToken,
                  userId: userLocal.idUser
                }
              axios.post(APICALL_URL,postData,
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                })
                .then(res => {
                    if(!res.data.tokenAuth) navigate("/login")
                    setSuccess(true)
                    setLoadingData(false)
                })
            } else {
                //
            }
      } else {
        setSuccess(true)
      }
    }, [loadingData]);

    //console.log(userLocal)
    return(
    <div className="container">
        { userLocal.user && (
            <>
            <p>Utilisateur connecté : <b>{userLocal.user}</b></p>
            <p className='fs08'>Version de l'application : <b>{version}</b></p>
            </>
            )}
    </div>
    )
}

export default Compte