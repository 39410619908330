import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from './pages/Home'
import Login from './pages/Login'
import Qreader from './pages/Qreader'
import References from './pages/References'
import Reference from './pages/Reference'
import ReferenceEdit from './pages/ReferenceEdit'
import ReferenceNew from './pages/ReferenceNew'
import Caisses from './pages/Caisses'
import Caisse from './pages/Caisse'
import CaisseEdit from './pages/CaisseEdit'
import Parametres from './pages/Parametres'
import Users from './pages/Users'
import Lieux from './pages/Lieux'
import NotFound from './pages/NotFound'
import Compte from './pages/Compte'
import Unauthorized from './components/Unauthorized'
import RequireAuth from './components/RequireAuth'
import ROLES from './hooks/roles'


function App() {

  return (
      <BrowserRouter>
          <Routes>
              <Route index element={<Home />} />
              <Route path="login" element={<Login />} />
              <Route path="unauthorized" element={<Unauthorized />} />
              <Route element={<RequireAuth allowedRoles={[ROLES.RegOne]}/>}>
                <Route path="qreader" element={<Qreader key='qreader' />} />
                <Route path="caisses" element={<Caisses page='caisses' key='caisses' />} />
                <Route path="caisses/:refId" element={<Caisse page='caisse' />} />
                <Route path="caisses/new" element={<Caisse page='caisse-new' />} />
                <Route path="caisses/edit/:refId" element={<CaisseEdit page='caisse-edit' />} />
                <Route path="parametres" element={<Parametres page='parametres' />} />
                <Route path="users" element={<Users page='users' />} />
                <Route path="lieux" element={<Lieux page='lieux' />} />
                <Route path="logs" element={<Parametres page='logs' />} />
                <Route path="synchro" element={<References page='synchro' />} />
                <Route path="synchro/edit/:refId" element={<ReferenceEdit page='reference-edit-local' />} />
                <Route path="references/tri/validees" element={<References page='references-validees' key='references-validees' />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={[ROLES.RegOne, ROLES.RegTwo]}/>}>
                <Route path="references/new" element={<ReferenceNew page='reference-new' />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={[ROLES.Validateur, ROLES.RegOne, ROLES.RegTwo]}/>}>
                <Route path="compte" element={<Compte page='compte' />} />
                <Route path="references" element={<References page='references' />} />
                <Route path="references/:refId" element={<Reference page='reference' />} />
                <Route path="references/edit/:refId" element={<ReferenceEdit page='reference-edit' />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={[ROLES.Invite, ROLES.Validateur, ROLES.RegOne, ROLES.RegTwo]}/>}>
                <Route path="archives" element={<References page='archives' />} />
                <Route path="archives/:refId" element={<Reference page='archive' />} />
              </Route>
              <Route path="*" element={<NotFound page='404' />} />
          </Routes>
      </BrowserRouter>
  );
}

export default App;
