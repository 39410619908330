import { useState, useEffect } from "react"
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
import { db } from "../hooks/db"
import {mess1List, mess2List, APICALL_URL} from '../hooks/data'
import { errorAff, paramMajBddLocale } from '../hooks/functions'
import { Button, Modal } from 'react-bootstrap'
import { XLg, CheckLg, Trash, Save2 } from "react-bootstrap-icons"

function Parametres({action, isOnline}){
    const initDisplay=[]
    const userLocal = JSON.parse(localStorage.getItem("user"))
    const[success, setSuccess] = useState(false)
    const[loadingData, setLoadingData] = useState(false)
    const [mess1, setMess1] = useState(mess1List[0])
    const [mess2, setMess2] = useState(mess2List[0])
    const [errMsg, setErrMsg] = useState('')
    const [paramsListe, setParamsListe] = useState([])
    const [changeDisplay, setChangeDisplay] = useState("all")
    const [display, setDisplay] = useState(initDisplay)
    const [disable, setDisable] = useState(false)
    const [show, setShow] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [localAction, setLocalAction]=useState()
    const [item, setItem] = useState()
    const [itemAdd, setItemAdd] = useState()
    const [catSelectedNom, setCatSelectedNom] = useState()
    const[listeCats,setListeCats] = useState()
    const [idItem, setIdItem] = useState()
    const [catItem, setCatItem] = useState()
    const [tableItem, setTableItem] = useState()

    const navigate = useNavigate()
    
    useEffect(() => {
        setLoadingData(true)
    }, []);

    useEffect(() => {
        if(isOnline){
            if(loadingData){
                setSuccess(false)
                const postData = {
                  action:"getParametres",
                  accessToken: userLocal.accessToken,
                  userId: userLocal.idUser
                }
              axios.post(APICALL_URL,postData,
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                })
                .then(res => {
                    if(!res.data.tokenAuth) navigate("/login")
                    setSuccess(true)
                    setShow(false)
                    setParamsListe(res.data.bdd)
                    let liste=[]
                    res.data.bdd.map(param=>
                        {if(param.table==="categories") 
                            param.cont.map(champ=>
                                liste[champ.id]=champ.nom)
                        }
                        )
                    setListeCats(liste)
                    // Maj Bdd locale
                    paramMajBddLocale(res.data.bdd) // enregisrtement en local
                    setLoadingData(false)
                })
            } else {
                //
            }
      } else {
        setSuccess(true)
      }
    }, [loadingData]);

    useEffect(() => {
        //console.log(changeDisplay)
        var disp
        disp=initDisplay
        paramsListe.map(param=>
            {if(param.table==="categories") 
                param.cont.map(champ=>
                    disp[champ.id]="none")
            }
            )
        if(changeDisplay=="all"){
            paramsListe.map(param=>
                {if(param.table==="categories") 
                    param.cont.map(champ=>
                        disp[champ.id]="inherit")
                }
                )
        } else {
            disp[changeDisplay]="inherit"
        }
        setDisplay(disp);
    }, [changeDisplay]);

    const handleClose = () => setShow(false)
    const handleCloseConfirm = () => setShowConfirm(false)

    function showModifItem(table,champ){
        setDisable(true)
        setItem(champ.nom)
        setIdItem(champ.id)
        setCatItem(champ.cat)
        setTableItem(table)
        setLocalAction("modifItem")
        setShow(true)
    }
    
    function showAddItem(param){
        let item1={}
        item1.label=param.label
        item1.table=param.table
        setItemAdd(item1)
        setDisable(true)
        setLocalAction("addItem")
        setShow(true)
    }

    const addItem = async (e) => {
        e.preventDefault()
        setMess1(mess1List[1])
        setMess2(mess2List[1])
        if(item.replace(/ /g, "")==="") return false
        const postData = {
            action:"addParametre",
            nom:item,
            table:itemAdd.table,
            cat:changeDisplay,
            accessToken: userLocal.accessToken,
            userId: userLocal.idUser
            }
        try {
            const response = await axios.post(APICALL_URL,postData,
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            if(response?.data?.retour){
                setLoadingData(true)
                setItem("")
            }
        } catch (err) {

        }
    }

    const modifItem = async (e) => {
        e.preventDefault()
        setMess1(mess1List[1])
        setMess2(mess2List[1])
        if(item.replace(/ /g, "")==="") return false
        const postData = {
            action:"modifParametre",
            nom:item,
            id:idItem,
            table:tableItem,
            accessToken: userLocal.accessToken,
            userId: userLocal.idUser
          }
        try {
            const response = await axios.post(APICALL_URL,postData,
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            if(response?.data?.retour){
                setLoadingData(true)
                setItem("")
            }
        } catch (err) {
            console.log(err)
        }
    }

    const supItem = async (e) => {
        e.preventDefault()
        setShow(false)
        setShowConfirm(true)
    }

    const supItemConfirm = async (e) => {
        e.preventDefault()
        setMess1(mess1List[2])
        setMess2(mess2List[2])
        const postData = {
            action:"supParametre",
            id:idItem,
            table:tableItem,
            accessToken: userLocal.accessToken,
            userId: userLocal.idUser
          }
          tableItem==="categories" && setChangeDisplay("all")
        try {
            const response = await axios.post(APICALL_URL,postData,
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            if(response?.data?.retour){
                setLoadingData(true)
                setShowConfirm(false)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const modal=<Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
        <Modal.Title>
        { localAction==="addItem" && <span>Ajouter {itemAdd["label"]} (catégorie : {listeCats[changeDisplay]})</span> }
        { localAction==="modifItem" && <span>Modifier …</span> }
        
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {localAction==="addItem" &&
        <form>
            <label className="w100">Intitulé</label>
            <input className="w100" placeholder="Saisir l'intitulé" onChange={(e) => {setItem(e.target.value); setDisable(false)}} type="text" name="nom"></input>
        </form>
        }
        {localAction==="modifItem" &&
        <form>
            <label className="w100">Modifier l'intitulé pour {item} :</label>
            <input className="w100" placeholder="Saisir l'intitulé" onChange={(e) => {setItem(e.target.value); setDisable(false)}} type="text" name="nom" value={item} />
        </form>
        }
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
        <XLg />&nbsp;&nbsp;Annuler
        </Button>
        {localAction==="modifItem" &&
        <>
        <Button variant="danger" onClick={supItem}>
            <Trash />&nbsp;&nbsp;Supprimer
        </Button>
            <Button variant="primary" onClick={modifItem} disabled={disable}>
                <Save2/>&nbsp;&nbsp;Modifier
            </Button>
        </>
        }
        {localAction==="addItem" &&
            <Button variant="primary" onClick={addItem} disabled={disable}>
            <CheckLg/> Ajouter
            </Button>
        }
        </Modal.Footer>
    </Modal>

    const confirm=<Modal show={showConfirm} onHide={handleCloseConfirm} centered>
        <Modal.Header closeButton>
        <Modal.Title>Confirmez la suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>Voulez-vous vraiment supprimer <b>{item}</b> ?</Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseConfirm}>
        <XLg /> Annuler
        </Button>
        <Button variant="primary" onClick={supItemConfirm}>
            <CheckLg/> Oui
        </Button>
        </Modal.Footer>
    </Modal>

    return(
    <div className="container">
        { isOnline ?
        <>
        { modal }
        { confirm }
        { !success && <p className="mess">{mess1}</p>}
        { success && <p className="mess mess_vert mess_court">{mess2}</p>}
        { errMsg!=="" && <p className="mess alerte">{errMsg}</p>}
        <div className="tar"><span className="picto_ssmenu update sc07" onClick={(e) =>setLoadingData(true)}></span></div>
        {success &&
        <>
        <div className="row">
            <div className="col-6">
                {paramsListe.map(param=>
                    <div key={"env1-"+param.table}>
                    {param.table==="categories" &&
                        <div key={"env-"+param.table} className="zoneParam"><h4>{param.nom}</h4>
                            <div>
                                {param.cont.map(champ=>
                                    <div 
                                        id={param.table+"-"+champ.id} 
                                        className="itemParam"
                                        key={param.table+"-"+champ.id}
                                        onClick={()=>{showModifItem(param.table,champ)}}
                                    >
                                        {champ.nom}
                                    </div>
                                    )}
                            </div>
                            <div className="bloc_plus mt-2" onClick={()=>{showAddItem(param)}}><span className="picto_admin plus"></span><span className="texte">Ajouter {param.label}</span></div>
                        </div>
                    }
                    </div>
                )}
            </div>
            <div className="col-6">
                <select className="mb-4 w100" value={changeDisplay} onChange={(e)=>setChangeDisplay(e.target.value)}>
                <option title="tout" value="all">Toutes les catégories</option>
                { paramsListe.map(param=>
                    param.table==="categories" &&
                        param.cont.map(champ=>
                         <option
                         key={champ.id}
                         value={champ.id}
                         >{champ.nom}
                         </option>
                        )
                    )}
                </select>
                {paramsListe.map(param=>
                    <div key={"env1-"+param.table}>
                    {(param.table!=="categories" && param.table!=="lieux" && param.table!=="stockage_types" && param.table!=="caisses") &&
                        <div key={"env-"+param.table} className="zoneParam"><h4>{param.nom}</h4>
                            <div>
                                {param.cont.map(champ=>
                                    <div
                                        className="itemParam"
                                        key={param.table+"-"+champ.id}
                                        onClick={()=>{showModifItem(param.table,champ)}}
                                        style={{display:display[champ.cat]}}
                                    >
                                        {champ.nom}
                                        </div>
                                    )}
                            </div>
                            {changeDisplay!=="all" &&
                            <div className="bloc_plus mt-2" onClick={()=>{showAddItem(param)}}><span className="picto_admin plus"></span><span className="texte">Ajouter {param.label} pour la catégorie {listeCats[changeDisplay]}</span></div>
                            }
                        </div>
                    }
                    </div>
                )}
            </div>
        </div>
        </>
        }
      </>
      :
      <div>Vous devez être connecté à internet</div> }
    </div>
    )
}

export default Parametres